import React from "react"

const ListLinkArrowSvg = () => (
  <svg width="12px" height="9px" viewBox="0 0 12 9">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Multi-Cloud-updated"
        transform="translate(-586.000000, -826.000000)"
        fill="#0F1E57"
        fillRule="nonzero">
        <g id="Group-2" transform="translate(150.000000, 502.000000)">
          <path
            d="M443.784306,324.088591 L443.853553,324.146447 L447.853553,328.146447 L447.865411,328.158714 C447.877061,328.171181 447.88808,328.184246 447.898417,328.197856 L447.853553,328.146447 C447.880388,328.173281 447.903534,328.202406 447.922993,328.233193 C447.929981,328.244318 447.936707,328.255953 447.942966,328.267873 C447.95025,328.281724 447.956649,328.295461 447.962389,328.309405 C447.967592,328.321975 447.972442,328.335263 447.976732,328.348801 C447.981552,328.364165 447.985604,328.379526 447.988914,328.395013 C447.991233,328.405629 447.993206,328.416616 447.994814,328.427722 C447.997343,328.445461 447.998938,328.462933 447.999619,328.480433 C447.999864,328.486395 448,328.493182 448,328.5 L447.999579,328.520569 C447.998877,328.537735 447.997295,328.554871 447.994833,328.571904 L448,328.5 C448,328.536097 447.996175,328.571301 447.988908,328.605228 C447.985604,328.620474 447.981552,328.635835 447.976759,328.651012 C447.972442,328.664737 447.967592,328.678025 447.962208,328.691037 C447.956649,328.704539 447.95025,328.718276 447.943195,328.731758 C447.936707,328.744047 447.929981,328.755682 447.922812,328.76701 C447.919267,328.772702 447.915405,328.778536 447.911409,328.784306 L447.898417,328.802144 C447.88808,328.815754 447.877061,328.828819 447.865411,328.841286 L447.853553,328.853553 L443.853553,332.853553 C443.658291,333.048816 443.341709,333.048816 443.146447,332.853553 C442.97288,332.679987 442.953595,332.410563 443.088591,332.215694 L443.146447,332.146447 L446.292,329 L436.5,329 C436.223858,329 436,328.776142 436,328.5 C436,328.25454 436.176875,328.050392 436.410124,328.008056 L436.5,328 L446.294,328 L443.146447,324.853553 C442.97288,324.679987 442.953595,324.410563 443.088591,324.215694 L443.146447,324.146447 C443.320013,323.97288 443.589437,323.953595 443.784306,324.088591 Z"
            id="Combined-Shape-Copy"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ListLinkArrowSvg
