import styles from "./../styles/home.module.scss"
import cblockStyles from "./../styles/commons/cblock.module.scss"
import checkStyles from "./../styles/commons/circlechecks.module.scss"
import React, { useState } from "react"
import Layout from "../components/layout/layout"
import StartDemo from "../components/start-demo/start-demo"
import { graphql, Link, useStaticQuery } from "gatsby"
import ListLinkArrowSvg from "../components/svg/list-link-arrow"
import SEO from "../components/seo"
import withAppContext from "../contexts/app.context"
import HomeHero from "../components/home-hero/home-hero"
import { TabPane, Tabs } from "../components/tab-components/tab-components"
import FsLightbox from "fslightbox-react"
import ArrowSvg from "../components/svg/arrow"
import PlaySvg from "../components/svg/play"
import { Helmet } from "react-helmet"
import { Helper } from "../utils/helper"

const IndexPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: { relativePath: { eq: "" }, category: { eq: "seo" } }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const quotes = [
    {
      author: {
        name: "Keiichi Makizono",
        position: "SVP & CIO, SoftBank Corp.",
      },
      logo: {
        url: "/img/main/quotes/softbank-c.svg",
        alt: "SoftBank logo",
      },
      text: `“We are excited to work with Volterra to architect an unprecedented edge computing environment across our 5G network. Volterra has demonstrated that it solves critical operational challenges within existing telco cloud service offerings, increasing operator efficiency and revenue streams while delivering a cloud-native experience that will drive developer adoption.”`,
    },
    {
      author: {
        name: "Romain Broussard",
        position: "IT Director, Cdiscount",
      },
      logo: {
        url: "/img/main/quotes/cdiscount-c.svg",
        alt: "Cdiscount logo",
      },
      text: `“As an online marketplace the availability of our website is critical, as every minute of downtime results in a significant loss of revenue and a deterioration of our brand. We have worked very closely with Volterra to maximize our resiliency and security, as well as our user experience, and have been able to build a complete application delivery network and several security tools with their VoltMesh service.”`,
    },
    {
      author: {
        name: "Fumihiko Shinoura",
        position: "SVP/GM Business Development, Net One Systems",
      },
      logo: {
        url: "/img/main/quotes/netone-c.svg",
        alt: "Net One logo",
      },
      text: `“VoltStack has dramatically simplified our ability to manage and secure distributed infrastructure, applications and data across edge and cloud.  Our teams now focus on applications, while Volterra handles the operations of the platform.”`,
    },
    {
      author: {
        name: "Gauthier Astruc Amato",
        position: "Head of Operations, Betclic",
      },
      logo: {
        url: "/img/main/quotes/betclic-c.svg",
        alt: "BetClic logo",
      },
      text: `“To provide the best playing experience for our players and the most secure environment we use Volterra's VoltMesh service and global private backbone”`,
    },
    {
      author: {
        name: "Julien Menard",
        position: "CTO, Digital Virgo",
      },
      logo: {
        url: "/img/main/quotes/digital-virgo-c.svg",
        alt: "Digital Virgo logo",
      },
      text: `"Volterra has been a great partner by delivering high-performance and secure networking services for our apps in the hybrid cloud. We rely on their 24x7 NOC and SOC to ensure that our end customers don’t experience any downtime,while freeing up resources on our operations team to focus on applications.”`,
    },
  ]

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0)

  const CustomerIcons = (
    <div className={styles.mainApplications__customersSliderBody}>
      <img
        src="/img/main/customers/betclic.svg"
        alt="BetClic logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/sigfox.svg"
        alt="SigFox logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/softbank.svg"
        alt="SoftBank logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/franceix.svg"
        alt="Franceix logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/webedia.svg"
        alt="Webedia logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/netone.svg"
        alt="Net One logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/kindred.svg"
        alt="Kindred logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/cdiscount.svg"
        alt="CDiscount logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/cybertest.svg"
        alt="CyberTest logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/digitalvirgo.svg"
        alt="Digital Virgo logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/mediapart.svg"
        alt="Mediapart logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/itochu.svg"
        alt="Itochu logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/relaxgaming.svg"
        alt="Relax Gaming logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/veepee.svg"
        alt="Veepee logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/scoopit.svg"
        alt="ScoopIT logo"
        className={styles.mainApplications__customersSliderItem}
      />
      <img
        src="/img/main/customers/keyyo-communications.svg"
        alt="Keyyo Communications logo"
        className={styles.mainApplications__customersSliderItem}
      />
    </div>
  )

  const storyVideoLink = `https://player.vimeo.com/video/425695172?autoplay=1`
  const vsVideoLink = "https://player.vimeo.com/video/444944008?autoplay=1"
  const vmVideoLink = "https://player.vimeo.com/video/438720787?autoplay=1"

  const [currentVideoUrl, setCurrentVideoUrl] = useState(storyVideoLink)
  const [isVideoOpened, setVideoOpened] = useState(false)

  const playVideo = videoUrl => {
    setCurrentVideoUrl(videoUrl)
    Helper.triggerGtagVideo(currentVideoUrl)
    setVideoOpened(!isVideoOpened)
  }

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "http://schema.org",
                "@type": "Corporation",
                "additionalType":"http://www.productontology.org/doc/Platform_as_a_service",
                "@id":"https://www.volterra.io/",
                "legalName": "Volterra Inc",
                "description": "Volterra offers a distributed cloud platform to deploy, connect, secure, observe and operate apps and data globally across multiple clouds and the edge",
                "url":"https://www.volterra.io/",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "San Jose, California, USA",
                  "postalCode": "CA 95134",
                  "streetAddress": "3545 N 1st St"
                },
                "email": "sales@volterra.io",
                "name": "Volterra Inc",
                "telephone": "+1 (408) 900-6788",
                "logo":"https://www.volterra.io/img/main/logo.svg",
                "image":"https://www.volterra.io/img/main/apps.png",
                "sameAs" :
                  [
                    "https://twitter.com/volterra_",
                    "https://www.linkedin.com/company/volterra-edge-services/",
                    "https://www.crunchbase.com/organization/volterra-inc"
                  ],
  
                "funder":
                  [
                    {
                      "@type": "Corporation",
                      "legalName": "Mayfield",
                      "url":"https://www.mayfield.com/",
                      "sameAs": "https://en.wikipedia.org/wiki/Mayfield_(company)"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Khosla Ventures",
                      "url":"https://www.khoslaventures.com/",
                      "sameAs": "https://en.wikipedia.org/wiki/Khosla_Ventures"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "(M12) Microsoftís venture fund",
                      "url":"https://m12.vc/",
                      "sameAs": "https://en.wikipedia.org/wiki/M12_(venture_capital)"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Samsung NEXT",
                      "url":"https://samsungnext.com/",
                      "sameAs": "https://www.crunchbase.com/organization/samsungnext"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Itochu Technology Ventures",
                      "url":"https://www.techv.co.jp/en/",
                      "sameAs": "https://www.crunchbase.com/organization/itochu-technology-ventures"
                    }
                  ],
  
                "owns":
                  [
                    {
                      "@type":"Product",
                      "name":"VoltShare",
                      "url":"https://www.volterra.io/products/voltshare",
                      "image":"https://www.volterra.io/img/products/voltshare.png",
                      "description":"Securely share your critical data while ensuring compliance. Simplify end-to-end encryption. Make your existing collaboration tools more secure."
                    },
                    {
                      "@type":"Product",
                      "name":"VoltMesh",
                      "url":"https://www.volterra.io/products/voltmesh",
                      "image":"https://www.volterra.io/img/products/voltmesh.png",
                      "description":"A SaaS-based offering that delivers high-performance networking and zero-trust security across clouds and edge sites along with the ability to offload apps on our global network"
                    },
                    {
                      "@type":"Product",
                      "name":"VoltStack",
                      "url":"https://www.volterra.io/products/voltstack",
                      "image":"https://www.volterra.io/img/products/voltstack.png",
                      "description":"SaaS-based offering that automates deployment, security and operations of distributed apps and infrastructure across the edge, public/private clouds and our global application delivery network"
                    },
                    {
                      "@type":"Product",
                      "name":"VoltConsole",
                      "url":"https://www.volterra.io/products/voltconsole",
                      "image":"https://www.volterra.io/img/products/voltconsole.png",
                      "description":"A SaaS-based operations and observability portal to manage infrastructure and apps across multi-cloud and edge, provide early warnings and generate actionable business insights"
                    },
                    {
                      "@type":"Product",
                      "name":"Volterra Global Network",
                      "url":"https://www.volterra.io/products/volterra-global-network",
                      "image":"https://www.volterra.io/img/products/global-network.png",
                      "description":"Industry's first app-to-app network and security service that's purpose built to connect and secure your global workloads across multi-cloud and edge"
                    }
                  ]
              }
          `}
        </script>
      </Helmet>

      <SEO
        title={metaTitle}
        pageUrl={"/"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero promo */}
      <HomeHero
        props={{
          openSelectedVideoFn: () => {
            playVideo(storyVideoLink)
          },
        }}
      />

      {/* Applications section */}
      <section
        className={`${cblockStyles.cblock} ${styles.mainApplications}`}
        ves-e2e-test="homepage/applications-section">
        <div className={styles.mainApplications__customersContainer}>
          <div className={`${styles.mainApplications__customers}`}>
            <h4 className={styles.mainApplications__customersTitle}>
              Trusted by customers globally
            </h4>
            <div className={styles.mainApplications__customersSlider}>
              {CustomerIcons}
              {CustomerIcons}
            </div>
          </div>
        </div>
        <div className={`container`}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Distributed cloud services — solve the most pressing challenges of
              app delivery
            </h2>
            <p className={styles.cblock__intro}>
              We help DevOps and NetOps teams ease their operational, security,
              and performance challenges as their apps and data get distributed
              across cloud or edge
            </p>
          </div>
          <div className={`${styles.mainApplications__list} row`}>
            <div className="col-12 col-lg-6">
              <Link
                to="/solutions/multi-cloud/multi-cloud-networking"
                className={styles.mainApplications__listItem}>
                <h3 className={styles.mainApplications__listItemTitle}>
                  <img
                    src="/img/solutions/flexibility-icon.svg"
                    alt="Multi-Cloud Networking & Security icon"
                    className={styles.mainApplications__listItemIcon}
                  />
                  Multi-Cloud Networking
                </h3>
                <p className={styles.mainApplications__listItemText}>
                  SaaS-based networking service to connect and secure modern and
                  legacy apps
                </p>
                <div className={styles.link}>
                  Learn more
                  <ArrowSvg></ArrowSvg>
                </div>
              </Link>
            </div>
            <div className="col-12 col-lg-6">
              <Link
                to="/solutions/network/web-app-security-and-performance"
                className={styles.mainApplications__listItem}>
                <h3 className={styles.mainApplications__listItemTitle}>
                  <img
                    src="/img/solutions/speed-icon.svg"
                    alt="App Performance & Reliability icon"
                    className={styles.mainApplications__listItemIcon}
                  />
                  App Performance & Reliability
                </h3>
                <p className={styles.mainApplications__listItemText}>
                  Secure API gateway and a load balancer that is distributed
                  across our global network and your cloud-based app clusters
                </p>
                <div className={styles.link}>
                  Learn more
                  <ArrowSvg></ArrowSvg>
                </div>
              </Link>
            </div>
            <div className="col-12 col-lg-6">
              <Link
                to="/solutions/network/app-delivery-network"
                className={styles.mainApplications__listItem}>
                <h3 className={styles.mainApplications__listItemTitle}>
                  <img
                    src="/img/solutions/sphere-icon.svg"
                    alt="App Delivery Network icon"
                    className={styles.mainApplications__listItemIcon}
                  />
                  App Delivery Network (ADN)
                </h3>
                <p className={styles.mainApplications__listItemText}>
                  Offload critical services from the cloud to our global app
                  delivery network and be closer to your end users
                </p>
                <div className={styles.link}>
                  Learn more
                  <ArrowSvg></ArrowSvg>
                </div>
              </Link>
            </div>
            <div className="col-12 col-lg-6">
              <Link
                to="/solutions/edge/edge-infrastructure-and-app-management"
                className={styles.mainApplications__listItem}>
                <h3 className={styles.mainApplications__listItemTitle}>
                  <img
                    src="/img/solutions/apps-icon.svg"
                    alt="Edge Infrastructure & Apps"
                    className={styles.mainApplications__listItemIcon}
                  />
                  Edge Infrastructure & Apps
                </h3>
                <p className={styles.mainApplications__listItemText}>
                  Simplify the platform, networking and security services for
                  apps deployed across a large number of edge sites
                </p>
                <div className={styles.link}>
                  Learn more
                  <ArrowSvg></ArrowSvg>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Volterra Offerings */}
      <section
        className={styles.mainOfferings}
        ves-e2e-test="homepage/offerings-section">
        <div className="container">
          <div className={`${styles.mainOfferings__wrapper}`}>
            <h2 className={styles.mainOfferings__title}>Volterra Offerings</h2>
            <p className={styles.mainOfferings__description}>
              Volterra provides a distributed cloud platform to deploy, connect,
              secure and operate apps and data across multiple clouds and edge
              sites.
            </p>
            <p className={styles.mainOfferings__description}>
              Our global app-to-app network allows you to secure cloud apps and
              offload the most critical workloads closer to end users for
              extreme performance
            </p>
          </div>
          <div
            className={`row ${styles.mainOfferings__products} ${styles.productList}`}>
            {/* VoltConsole */}
            <div className={styles.productList__item}>
              <div
                className={`col-12 col-md-5 ${styles.productList__itemWrapper}`}>
                <div className={styles.productList__itemHeader}>
                  <div
                    className={`${styles.productList__itemLogo} ${styles.productList__itemLogoVc}`}
                  />
                  <h2 className={styles.productList__itemTitle}>
                    VoltConsole <span>&trade;</span>
                  </h2>
                </div>
                <p className={styles.productList__itemDescription}>
                  SaaS-based portal to centrally manage the delivery of apps by
                  VoltMesh and VoltStack services
                </p>
                <Link
                  ves-e2e-test="homepage/offerings-btn"
                  className={styles.productList__itemButton}
                  to={"/products/voltconsole"}>
                  Learn more
                  <ArrowSvg />
                </Link>
              </div>
              <div className={styles.productList__itemImageWrapper}>
                <picture>
                  <img
                    src="/img/main/vc-img.png"
                    alt="VoltConsole 3D diagram"
                  />
                </picture>
              </div>
            </div>

            {/* VoltStack */}
            <div className={styles.productList__item}>
              <div
                className={`col-12 col-md-5 ${styles.productList__itemWrapper}`}>
                <div className={styles.productList__itemHeader}>
                  <div
                    className={`${styles.productList__itemLogo} ${styles.productList__itemLogoVs}`}
                  />
                  <h2 className={styles.productList__itemTitle}>
                    VoltStack <span>&trade;</span>
                  </h2>
                </div>
                <p className={styles.productList__itemDescription}>
                  Platform service to automate the deployment, security and
                  operations of workloads distributed across clouds, the edge or
                  our global network
                </p>
                <div className="d-flex">
                  <Link
                    ves-e2e-test="homepage/offerings-btn"
                    className={styles.productList__itemButton}
                    to={"/products/voltstack"}>
                    Learn more
                    <ArrowSvg />
                  </Link>
                  <button
                    ves-e2e-test="homepage/offerings-btn-video"
                    className={`${styles.productList__itemButton} ${styles.productList__itemButtonVideo}`}
                    onClick={() => {
                      playVideo(vsVideoLink)
                    }}>
                    Watch video
                    <PlaySvg />
                  </button>
                </div>
              </div>
              <div className={styles.productList__itemImageWrapper}>
                <picture>
                  <img src="/img/main/vs-img.png" alt="VoltStack 3D diagram" />
                </picture>
              </div>
            </div>

            {/* VoltMesh */}
            <div
              className={`${styles.productList__itemVm} ${styles.productList__item}`}>
              <div
                className={`col-12 col-md-5 ${styles.productList__itemWrapper}`}>
                <div className={styles.productList__itemHeader}>
                  <div
                    className={`${styles.productList__itemLogo} ${styles.productList__itemLogoVm}`}
                  />
                  <h2 className={styles.productList__itemTitle}>
                    VoltMesh <span>&trade;</span>
                  </h2>
                </div>
                <p className={styles.productList__itemDescription}>
                  Secure connectivity service that delivers high-performance
                  networking and zero-trust security across multiple clouds and
                  edge sites
                </p>
                <div className="d-flex">
                  <Link
                    ves-e2e-test="homepage/offerings-btn"
                    className={styles.productList__itemButton}
                    to={"/products/voltmesh"}>
                    Learn more
                    <ArrowSvg />
                  </Link>
                  <button
                    ves-e2e-test="homepage/offerings-btn-video"
                    className={`${styles.productList__itemButton} ${styles.productList__itemButtonVideo}`}
                    onClick={() => {
                      playVideo(vmVideoLink)
                    }}>
                    Watch video
                    <PlaySvg />
                  </button>
                </div>
              </div>
              <div className={styles.productList__itemImageWrapper}>
                <picture>
                  <img src="/img/main/vm-img.png" alt="VoltMesh 3D diagram" />
                </picture>
              </div>
            </div>

            {/* Volterra Global Network */}
            <div
              className={`${styles.productList__item} ${styles.productList__itemVgn}`}>
              <div
                className={`col-12 col-md-5 ${styles.productList__itemWrapper}`}>
                <div className={styles.productList__itemHeader}>
                  <div
                    className={`${styles.productList__itemLogo} ${styles.productList__itemLogoVgn}`}
                  />
                  <h2 className={styles.productList__itemTitle}>
                    Volterra <br /> Global Network
                  </h2>
                </div>
                <p className={styles.productList__itemDescription}>
                  Industry’s first app-to-app network purpose-built for modern
                  application delivery and the ability to host workloads
                </p>
                <Link
                  ves-e2e-test="homepage/offerings-btn"
                  className={styles.productList__itemButton}
                  to={"/products/volterra-global-network"}>
                  Learn more
                  <ArrowSvg />
                </Link>
              </div>
              <div className={styles.productList__itemImageWrapper}>
                <picture>
                  <img
                    src="/img/main/vgn-img.png"
                    alt="Volterra Global Network 3D diagram"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Distributed cloud services */}
      <section
        className={cblockStyles.cblock}
        ves-e2e-test="homepage/solutions-tab-section">
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Distributed cloud services exactly where your apps & data need
              them
            </h2>
            <p className={cblockStyles.cblock__intro}>
              Navigate the transition to multi-cloud and distributed edge, while
              simplifying the deployment, security, and operations of your
              applications and infrastructure
            </p>
          </div>
          <div className={cblockStyles.cblock__body}>
            <Tabs className={styles.tabsblock}>
              <TabPane title="Multi-cloud" ves-e2e-test="homepage/">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-7 text-center">
                    <img
                      src="/img/main/multi-img.png"
                      alt="Screenshot showing Site dashboard"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-5">
                    <h3
                      className={styles.tabsblock__title}
                      ves-e2e-test="homepage/solutions-tab-title">
                      Multi-Cloud
                    </h3>
                    <p className={styles.tabsblock__intro}>
                      Provide consistent services and security across multiple
                      public and/or private clouds:
                    </p>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        <Link
                          ves-e2e-test="homepage/solutions-tab-link"
                          className={styles.tabsblock__link}
                          to={"/solutions/multi-cloud/multi-cloud-networking"}>
                          Consolidate networking, security, and application
                          delivery across cloud providers
                          <ListLinkArrowSvg />
                        </Link>
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        <Link
                          ves-e2e-test="homepage/solutions-tab-link"
                          className={styles.tabsblock__link}
                          to={
                            "/solutions/multi-cloud/secure-kubernetes-gateway"
                          }>
                          Advanced Kubernetes gateway with integrated security
                          and API services across one or multiple clusters
                          <ListLinkArrowSvg />
                        </Link>
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        <Link
                          ves-e2e-test="homepage/solutions-tab-link"
                          className={styles.tabsblock__link}
                          to={
                            "/solutions/network/web-app-security-and-performance"
                          }>
                          Globally distributed app gateway to deliver very high
                          performance SaaS apps
                          <ListLinkArrowSvg />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>
              <TabPane title="Network Cloud">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-7 text-center">
                    <img
                      src="/img/main/network-img.png"
                      alt="Screenshot showing Site dashboard"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-5">
                    <h3
                      className={styles.tabsblock__title}
                      ves-e2e-test="homepage/solutions-tab-title">
                      Network Cloud
                    </h3>
                    <p className={styles.tabsblock__intro}>
                      Leverage network-based cloud services for app security,
                      multi-cloud connectivity, distributed API delivery, and
                      accelerated apps closer to the user
                    </p>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        <Link
                          ves-e2e-test="homepage/solutions-tab-link"
                          className={styles.tabsblock__link}
                          to={
                            "/solutions/network/web-app-security-and-performance"
                          }>
                          Secure and accelerate your private, hybrid or
                          multi-cloud web apps + APIs
                          <ListLinkArrowSvg />
                        </Link>
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        <Link
                          ves-e2e-test="homepage/solutions-tab-link"
                          className={styles.tabsblock__link}
                          to={"/solutions/network/app-delivery-network"}>
                          Network-hosted workloads to improve the end-user
                          experience
                          <ListLinkArrowSvg />
                        </Link>
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        <Link
                          ves-e2e-test="homepage/solutions-tab-link"
                          className={styles.tabsblock__link}
                          to={"/solutions/network/ddos-mitigation"}>
                          DDoS, advanced security and IP transit services for
                          private or hybrid clouds
                          <ListLinkArrowSvg />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>
              <TabPane title="Edge Cloud">
                <div className={"row " + styles.tabsblock__content}>
                  <div className="col-xl-7 text-center">
                    <img
                      src="/img/main/edge-img.png"
                      alt="Screenshot showing Site dashboard"
                      className={"img-fluid " + styles.tabsblock__screenshot}
                    />
                  </div>
                  <div className="col-xl-5">
                    <h3
                      className={styles.tabsblock__title}
                      ves-e2e-test="homepage/solutions-tab-title">
                      Edge Cloud
                    </h3>
                    <p className={styles.tabsblock__intro}>
                      Bring cloud infrastructure and operations model to the
                      edge:
                    </p>
                    <ul className={"list-unstyled " + checkStyles.circlechecks}>
                      <li className={checkStyles.circlechecks__item}>
                        <Link
                          ves-e2e-test="homepage/solutions-tab-link"
                          className={styles.tabsblock__link}
                          to={
                            "/solutions/edge/edge-infrastructure-and-app-management"
                          }>
                          Cloud-native compute, storage, network and security
                          services across edge sites
                          <ListLinkArrowSvg />
                        </Link>
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        <Link
                          ves-e2e-test="homepage/solutions-tab-link"
                          className={styles.tabsblock__link}
                          to={"/solutions/edge/edge-networking-and-security"}>
                          Secure edge gateway to consolidate SDWAN, security and
                          API gateway
                          <ListLinkArrowSvg />
                        </Link>
                      </li>
                      <li className={checkStyles.circlechecks__item}>
                        <Link
                          ves-e2e-test="homepage/solutions-tab-link"
                          className={styles.tabsblock__link}
                          to={"/products/volterra-global-network"}>
                          Volterra global network to securely connect globally
                          distributed edge site to cloud
                          <ListLinkArrowSvg />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>

      <section
        className={cblockStyles.cblock}
        ves-e2e-test="homepage/quotes-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {quotes.map(
                (quote, index) =>
                  currentQuoteIndex === index && (
                    <div className={styles.quote__wrapper} key={index}>
                      <div className={styles.quote__header}>
                        <div className={styles.quote__headerImage}>
                          <img src={quote.logo.url} alt={quote.logo.alt} />
                        </div>
                      </div>

                      <p className={styles.quote__text}>{quote.text}</p>

                      <div className={styles.quote__author}>
                        <p
                          ves-e2e-test="homepage/quote-author"
                          className={styles.quote__authorName}>
                          {quote.author.name}
                        </p>
                        <p className={styles.quote__authorPosition}>
                          {quote.author.position}
                        </p>
                      </div>

                      <div className={`${styles.quote__nav}`}>
                        {quotes.map((_, index) => (
                          <span
                            ves-e2e-test="homepage/quote-nav"
                            key={index}
                            onClick={() => setCurrentQuoteIndex(index)}
                            className={`${styles.quote__navItem} ${
                              currentQuoteIndex === index
                                ? styles.quote__navItemActive
                                : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </section>

      <StartDemo />

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            src={currentVideoUrl}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
    </Layout>
  )
}

export default withAppContext(IndexPage)
